$(document).ready(function () {
    initMaps();
});
function initMaps() { 
    if ($('#mapLocationSrc').length < 1) {
        return;
    }

    var styledMap = new google.maps.StyledMapType(subtleGrayscaleMapStyle, { name: "Styled Map" });

    var mapElement = $('#mapLocationSrc');

    var map = new google.maps.Map(mapElement.get(0), {
        center: new google.maps.LatLng(latitude, longitude),
        zoom: zoomLevel,
        zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP
        },
        streetViewControl: false,
        scrollwheel: false,
        mapTypeControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    }),
    marker = new RichMarker({
        position: new google.maps.LatLng(latitude, longitude),
        map: map,
        shadow: 'none',
        content: 
        '<div class="map__marker">' + 
        '<i class="icon-location map__marker-icon"></i>' +
        '<div href="/" class="map__marker-logo">Blakemore</div>' +
        '</div>'
    }),
    info = new google.maps.InfoWindow({
        content: popupContent
    });

    map.mapTypes.set('map_style', styledMap);
    map.setMapTypeId('map_style');

    // resize map when parent div gets equalized
    mapElement.closest('[data-equalizer]').on('postequalized.zf.equalizer', function (e) {
        google.maps.event.trigger(map, 'resize');
    });

    var old_v = '',
    ds = new google.maps.DirectionsService(),
    dr = new google.maps.DirectionsRenderer();
    dr.setMap(map);
    dr.setPanel($('#map-directions-data').get(0));

    $('#frmPostcode').bind('submit', function () {
        var v = $.trim($('#tbPostcode.origin').val());
        var dest = $.trim($('#tbPostcode.destination').val());
        var tm = $('.mode-select input[type="radio"]:checked').val();
        var travelModeTransport = '';

        if ( tm == 'DRIVING' ) {
            travelModeTransport = google.maps.TravelMode.DRIVING;
        } else if ( tm == 'WALKING' ) {
            travelModeTransport = google.maps.TravelMode.WALKING;
        } else {
            travelModeTransport = google.maps.TravelMode.TRANSIT;
        }

        // if (v != '' && v != old_v) {
        //     old_v = v;
        var r = {
            origin: v,
            destination: dest,
            travelMode: travelModeTransport,
            unitSystem: google.maps.UnitSystem.IMPERIAL
        };
        ds.route(r, function (result, status) {
            if (status == google.maps.DirectionsStatus.OK) {
                info.close();
                marker.setMap(null);
                dr.setDirections(result);
                $('#map-directions').css({ display: 'block' });
                $('#print-directions').show();
                setTimeout(function () {
                    var x = document.getElementById("map-directions-data").innerHTML;
                    var str = x;
                    str = str.replace(/&/g, "amp;");
                    str = str.replace(/>/g, "gt;");
                    str = str.replace(/</g, "lt;");
                    str = str.replace(/"/g, "quot;");
                    str = str.replace(/'/g, "#039;");
                }, 2000);
            } else {
                alert('Location ' + old_v + ' not found.');
            }
        });
        // }
    });
}


var subtleGrayscaleMapStyle = [
{
    "featureType": "administrative",
    "elementType": "all",
    "stylers": [
    {
        "visibility": "on"
    },
    {
        "lightness": 33
    }
    ]
},
{
    "featureType": "administrative",
    "elementType": "labels",
    "stylers": [
    {
        "saturation": "-100"
    }
    ]
},
{
    "featureType": "administrative",
    "elementType": "labels.text",
    "stylers": [
    {
        "gamma": "0.75"
    }
    ]
},
{
    "featureType": "administrative.neighborhood",
    "elementType": "labels.text.fill",
    "stylers": [
    {
        "lightness": "-37"
    }
    ]
},
{
    "featureType": "landscape",
    "elementType": "geometry",
    "stylers": [
    {
        "color": "#f8f6f6"
    }
    ]
},
{
    "featureType": "landscape.man_made",
    "elementType": "geometry",
    "stylers": [
    {
        "saturation": "-100"
    },
    {
        "lightness": "40"
    },
    {
        "visibility": "off"
    }
    ]
},
{
    "featureType": "landscape.natural",
    "elementType": "labels.text.fill",
    "stylers": [
    {
        "saturation": "-100"
    },
    {
        "lightness": "-37"
    }
    ]
},
{
    "featureType": "landscape.natural",
    "elementType": "labels.text.stroke",
    "stylers": [
    {
        "saturation": "-100"
    },
    {
        "lightness": "100"
    },
    {
        "weight": "2"
    }
    ]
},
{
    "featureType": "landscape.natural",
    "elementType": "labels.icon",
    "stylers": [
    {
        "saturation": "-100"
    }
    ]
},
{
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
    {
        "saturation": "-100"
    },
    {
        "lightness": "80"
    }
    ]
},
{
    "featureType": "poi",
    "elementType": "labels",
    "stylers": [
    {
        "saturation": "-100"
    },
    {
        "lightness": "0"
    }
    ]
},
{
    "featureType": "poi.attraction",
    "elementType": "geometry",
    "stylers": [
    {
        "lightness": "-4"
    },
    {
        "saturation": "-100"
    }
    ]
},
{
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
    {
        "color": "#c5dac6"
    },
    {
        "visibility": "on"
    },
    {
        "saturation": "-43"
    },
    {
        "lightness": "34"
    }
    ]
},
{
    "featureType": "poi.park",
    "elementType": "labels",
    "stylers": [
    {
        "visibility": "on"
    },
    {
        "lightness": 20
    }
    ]
},
{
    "featureType": "road",
    "elementType": "all",
    "stylers": [
    {
        "lightness": 20
    }
    ]
},
{
    "featureType": "road",
    "elementType": "labels",
    "stylers": [
    {
        "saturation": "-100"
    },
    {
        "gamma": "1.00"
    }
    ]
},
{
    "featureType": "road",
    "elementType": "labels.text",
    "stylers": [
    {
        "gamma": "0.50"
    }
    ]
},
{
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
    {
        "saturation": "-100"
    },
    {
        "gamma": "0.50"
    }
    ]
},
{
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
    {
        "color": "#c5c6c6"
    },
    {
        "saturation": "-100"
    }
    ]
},
{
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
    {
        "lightness": "-13"
    }
    ]
},
{
    "featureType": "road.highway",
    "elementType": "labels.icon",
    "stylers": [
    {
        "lightness": "0"
    },
    {
        "gamma": "1.09"
    }
    ]
},
{
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
    {
        "color": "#e4d7c6"
    },
    {
        "saturation": "-100"
    },
    {
        "lightness": "47"
    }
    ]
},
{
    "featureType": "road.arterial",
    "elementType": "geometry.stroke",
    "stylers": [
    {
        "lightness": "-12"
    }
    ]
},
{
    "featureType": "road.arterial",
    "elementType": "labels.icon",
    "stylers": [
    {
        "saturation": "-100"
    }
    ]
},
{
    "featureType": "road.local",
    "elementType": "geometry",
    "stylers": [
    {
        "color": "#fbfaf7"
    },
    {
        "lightness": "77"
    }
    ]
},
{
    "featureType": "road.local",
    "elementType": "geometry.fill",
    "stylers": [
    {
        "lightness": "-5"
    },
    {
        "saturation": "-100"
    }
    ]
},
{
    "featureType": "road.local",
    "elementType": "geometry.stroke",
    "stylers": [
    {
        "saturation": "-100"
    },
    {
        "lightness": "-15"
    }
    ]
},
{
    "featureType": "transit.station.airport",
    "elementType": "geometry",
    "stylers": [
    {
        "lightness": "47"
    },
    {
        "saturation": "-100"
    }
    ]
},
{
    "featureType": "water",
    "elementType": "all",
    "stylers": [
    {
        "visibility": "on"
    },
    {
        "color": "#c3a47f"
    },
    {
        "lightness": "55"
    },
    {
        "saturation": "-59"
    }
    ]
},
{
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
    {
        "saturation": "53"
    }
    ]
},
{
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
    {
        "lightness": "-42"
    },
    {
        "saturation": "17"
    }
    ]
},
{
    "featureType": "water",
    "elementType": "labels.text.stroke",
    "stylers": [
    {
        "lightness": "61"
    }
    ]
}
];


